@import '../../assets/scss/variable';
.c-list {
  padding: 0;
  margin: 0;
  list-style: none;
  &.card {
    background: #e8e7e7;
    border-radius: 0;
    border: none;
    li {
      flex-direction: column;
      padding: 0;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      img {
        width: 100%;
        height: 135px;
        object-fit: cover;
      }
      span {
        padding: 15px;
        font-family: $font-bebasNeue-regular;
        font-size: 18px;
        line-height: 15px;
      }
    }
  }
  &.normal-list{
    li {
      cursor: pointer;
      margin: 0 25px 15px;
      border-radius: 12px;
      img {
        width: 78px;
        height: 67px;
        object-fit: cover;
      }
      .list-item-content{
        h4 {
          img {
            width: 20px;
            height: 12px;
            object-fit: contain;
            margin-right: 5px;
          }
          font-size: 14px;
          font-family: $font-bold;
          display: flex;
          align-items: center;
        }
        p {
          font-size: 10px;
          font-family: $font-regular;
        }
      }
    }
  }
  li {
    display: flex;
    background: white;
    padding: 8px;
    margin: 10px;
    box-shadow: 0 0 5px -3px black;
    img {
      width: 85px;
      height: 85px;
      object-fit: cover;
    }
    .list-item-content {
      flex: 1;
      margin: 0 15px 0 10px;
      &.list-item-menu-content {
        margin: 0 0 0 10px;
        display: flex;
        flex-direction: column;
      }
      h4 {
        margin: 0 0 5px 0;
        font-size: 14px;
        text-transform: uppercase;
        font-family: $font-bold;
      }
      p {
        margin: 0;
        font-size: 10px;
        font-family: $font-medium;
      }
      .button-container {
        flex: 1;
        margin-top: 5px;
        justify-content: flex-end;
      }
    }
    .button-container {
     display: flex;
      align-items: flex-end;
      span {
        flex: 1;
        font-family: $font-medium;
        font-size: 14px;
        padding: 3.5px 0;
      }
      button {
        font-size: 12px;
        min-width: 100px;
        font-family: $font-regular;
        background: #E01E30;
        color: white;
        border: none;
        padding: 5px;
        outline: none;
      }
    }
  }
}
