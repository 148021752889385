.header {
  padding: 10px;
  background: white;
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 9;
  box-shadow: 0 0 5px -2px black;
  .logo-container {
     width: 72px;
     height: 38px;
    display: flex;
    flex: 1;
    &.center{
      justify-content: center;
      align-items: center;
      margin: 0 15px;
    }
    img {
      width: 72px;
      height: 38px;
    }
  }
  button {
    padding: 0;
    &.cart-button {
      position: relative;
      padding: 0 5px;
      span {
        position: absolute;
        border-radius: 100px;
        min-width: 18px;
        font-size: 10px;
        right: 0;
        top: 0;
        background: #ef2b2d;
        color: white;
        font-family: $font-bold;
        padding: 2px;
      }
    }
    background: transparent;
    outline: none;
    border: none;
    svg {
      font-size: 24px;
    }
  }
}
