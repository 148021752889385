.variations {
  width: 100%;
  .variations-container {
    padding: 20px;
    width: 100%;
    .variations-card {
      border-radius: 5px;
      box-shadow: 0 0 5px -2px black;
      width: 100%;
      background: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      .variations-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 10px 15px;
        border-bottom: 2px solid #e8e7e7;
        width: 100%;
        margin: 0 auto;
        img {
          width: 150px;
          height: 150px;
          object-fit: cover;
        }
        h1 {
          margin: 20px 0 10px;
          font-size: 18px;
          font-family: $font-regular;
          line-height: 14px;
        }
        p {
          margin: 0;
          font-size: 12px;
          font-family: $font-light;
          text-align: center;
        }
      }
      .list {
        width: 100%;
        .list-header {
          display: flex;
          padding: 15px 0;
          .list-header-left {
            flex: 1;
            display: flex;
            flex-direction: column;
            span {
              &:last-child {
                font-size: 10px;
                color: #9B9A9A;
              }
            }
          }
          span {
            color: black;
            font-family: $font-regular;
            font-size: 14px;
          }
        }
        .list-item {
          display: flex;
          flex-direction: row;
          margin-bottom: 15px;
          padding: 0;
          align-items: center;
          cursor: pointer;
          span {
            font-size: 14px;
            font-family: $font-regular;
            color: #000000;
            line-height: 12px;
            &.active {
              border-color: #E21D30 !important;
              &:before {
                background: #E21D30 !important;
              }
            }
            &:first-child {
              width: 16px;
              height: 16px;
              border-radius: 16px;
              border: 1px solid #9B9A9A;
              margin-right: 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              &:before {
                content: '';
                width: 8px;
                height: 8px;
                border-radius: 8px;
                display: flex;
                background: transparent;
              }
            }
            &:nth-child(2) {
              flex: 1;
            }
            &:last-child {}
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .variations-body {
        width: 100%;
        margin: 0 auto;
      }
      .variations-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 25px;
        width: 100%;
        .counter {
          display: flex;
          align-items: center;
          flex: 1;
          button {
            background: transparent;
            color: #ef2b2d;
            padding: 5px 15px;
            outline: none;
            font-size: 38px;
            line-height: 18px;
          }
          span {
            padding: 0 15px;
            font-family: $font-regular;
            font-size: 16px;
          }
        }
        button {
          padding: 5px 35px;
          background: #E21D30;
          border: none;
          font-size: 16px;
          font-family: $font-regular;
          color: white;
          box-shadow: none !important;
        }
      }
    }
  }
}
