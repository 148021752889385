@import '~bootstrap/dist/css/bootstrap.min.css';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import '~antd/dist/antd.css';
@import './assets/scss';

html, body, #root, .app {
  margin: 0;
  height: 100%;
}
.ant-message {
  //top: auto;
  //bottom: 15px;
  .ant-message-custom-content {
    display: flex;
    align-items: baseline;
  }
}