.home {
  h4 {
    margin: 0;
    font-family: $font-bebasNeue-regular;
    font-size: 26px;
    text-align: center;
    padding: 10px 15px;
  }
.deals-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  min-width: 275px;
  margin: 0 auto 20px;
  max-width: 380px;
  a {
    flex: 1;
    display: flex;
    width: auto;
    height: auto;
    min-width: 110px;
    max-width: 170px;
    min-height: 110px;
    max-height: 170px;
    margin: 10px;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 5px -2px black;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.select-preference{
    margin: 30px 0 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    a {
      display: flex;
      min-width: 110px;
      max-width: 170px;
      min-height: 110px;
      max-height: 170px;
      margin: 0 10px;
      border-radius: 5px;
      overflow: hidden;
      box-shadow: 0 0 5px -2px black;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
