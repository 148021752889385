*{
  outline: none !important;
}
.custom-button {
  box-shadow: none !important;
  border-radius: 6px;
  padding: 10px;
  width: 100%;
  a {
    font-size: 18px;
    font-family: $font-openSans-semiBold;
    text-decoration: none;
  }
}
.green-button {
  background: $green;
  border-color: $green;
  outline-color: $green;
  &:focus{
    background: $green !important;
    border-color: $green !important;
  }
  &:active{
    background: $green !important;
    border-color: $green !important;
  }
  &:hover{
    background: $green !important;
    border-color: $green !important;
  }
  a {
    color: $white;
    display: flex;
    justify-content: center;
    margin: -10px;
    padding: 10px;
  }
}
.gray-button {
  background: $lightenGray;
  font-family: $font-openSans-semiBold;
  border-color: $lightenGray;
  outline-color: $lightenGray;
  a {
    color: $darkenGray;
  }
  &:focus{
    background: $lightenGray !important;
    border-color: $lightenGray !important;
  }
  &:active{
    background: $lightenGray !important;
    border-color: $lightenGray !important;
  }
  &:hover{
    background: $lightenGray !important;
    border-color: $lightenGray !important;
  }
}
.tab-with-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  .inner-container {
    flex: 1;
    overflow-y: auto;
    background: #E8E7E7;
  }
  .tabs {
    position: relative;
    z-index: 9;
    box-shadow: 0px 0px 5px -3px black;
    display: flex;
    a {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      padding: 12px 10px;
      span {
        color: #676767;
        font-size: 12px;
        font-family: $font-medium;

      }
      svg {
        color: #676767;
        width: 22px;
        height: 22px;
        margin-bottom: 5px;
      }

      &.active {
        span {
          color: #DA1F30;
        }
        svg {
          color: #DA1F30;
        }
      }
    }
  }
}
.normal-banner {
  width: 100%;
  height: 210px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.section-slider {
  background: white;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 15px;
  &.counter-section {
    display: flex;
    align-items: center;
    h1 {
      flex: 1;
      margin: 0;
    }
    .counter {
      display: flex;
      border: 1px solid black;
      button {
        background: transparent;
        border: none;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px 12px;
        font-size: 16px;
        &.minus {
          background: #D7544A;
          border-right: 1px solid black;
        }
        &.plus {
          border-left: 1px solid black;
          background: #5BB65D;
        }
      }
      span {
        padding: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
      }
    }
  }
  h1 {
    margin: 0 0 7px 0;
    font-size: 16px;
    font-family: $font-bebasNeue-regular;
  }
  ul {
    margin: 0;
    padding: 2px;
    list-style: none;
    display: flex;
    overflow-x: auto;
    &.circle-slider {
      li {
        width: 82px;
        min-width: 82px;
        height: 82px;
        border-radius: 82px;
        overflow: hidden;
        span {
          font-size: 10px;
          font-family: $font-extraBold;
          white-space: normal;
          word-break: break-word;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    li {
      display: flex;
      flex-direction: column;
      padding: 0;
      margin: 0 12px 0 0;
      box-shadow: 0 0 7px -4px black;
      cursor: pointer;
      width: 90px;
      &.active {
        span {
          background: black;
          color: white;
        }
      }
      a {
        text-decoration: none;
      }
      img {
        width: 90px;
        height: 90px;
        object-fit: cover;
      }
      span {
        //transition: 0.3s;
        padding: 5px;
        font-size: 10px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        color: #000000;
      }
    }
  }
}
.custom-alert {
  background: black;
  color: white;
  padding: 5px;
  p {
    margin: 0;
    font-size: 10px;
    text-align: center;
  }
}
.c-tab {
  flex-wrap: nowrap;
  overflow-x: auto;
  border: none !important;
  overflow-y: hidden;
  background: white !important;
  flex-direction: row;
  box-shadow: 0 0 5px -3px black;
  position: relative;
  z-index: 1;
  a {
    margin: 0 !important;
    border: none !important;
    color: black;
    font-family: $font-bebasNeue-regular;
    font-size: 16px;
    border-radius: 0 !important;
    padding: 10px 15px;
    text-transform: uppercase;
    white-space: nowrap;
    outline: none;
    border-bottom: 2px solid black !important;
    flex: 1;
    text-align: center;
    &.active {
      border-bottom-color: #E01E30 !important;
      color: #E01E30 !important;
    }
  }
}
.c-nav {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
}
.title {
  text-align: center;
  margin: 0 0 10px 0;
  font-size: 26px;
  font-family: $font-bebasNeue-regular;
}
.preference-section {
  padding: 15px;
  h6{
    margin: 0;
    text-align: center;
    font-size: 15px;
    font-family: $font-bebasNeue-regular;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    line-height: 13px;
    &:before {
      content: '';
      flex: 1;
      height: 1px;
      background: black;
      margin-right: 10px;
    }
    &:after {
      content: '';
      flex: 1;
      height: 1px;
      background: black;
      margin-left: 10px;
    }
  }
  .preference-list {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    .preference-list-item{
      display: flex;
      flex-direction: column;
      padding: 0;
      margin: 0;
      box-shadow: 0 0 7px -4px black;
      cursor: pointer;
      width: 172px;
      &.active {
        span {
          background: black;
          color: white;
        }
      }
      a {
        text-decoration: none;
      }
      img {
        width: 100%;
        height: 87px;
        object-fit: cover;
      }
      span {
        transition: 0.3s;
        padding: 5px;
        font-size: 10px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        color: #000000;
        background: white;
        font-family: $font-extraBold;
      }
    }
    .or {
      font-size: 15px;
      color: black;
      font-family: $font-bebasNeue-regular;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 15px;
    }
  }
}
.secondary-banner {
  height: auto;
  img {
    height: 140px;
  }
  .banner-content {
    background: white;
    padding: 15px;
    h4 {
      margin: 0 0 5px;
      font-size: 26px;
      font-family: $font-bebasNeue-regular;
      text-align: center;
      color: black;
      line-height: 22px;
    }
    p {
      margin: 0;
      text-align: center;
      color: black;
      font-size: 14px;
      font-family: $font-light;
    }
  }
}
.c-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding:  10px 0 20px;
  button {
    background: #FCB913;
    color: white;
    text-transform: uppercase;
    min-width: 155px;
    border-radius: 5px;
    font-size: 11px;
    font-family: $font-bold;
    padding: 10px 5px;
    border: none;
    outline: none;
    &:disabled {
      opacity: 0.6;
    }
  }
}
.header-with-body {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .body {
    overflow-y: auto;
    flex: 1;
    //height: calc(100vh - 58px);
    background: #E8E7E7;
    display: flex;
    flex-direction: column;
  }
}

.c-input {
  border-radius: 6px;
  border-color: #E8E8E9 !important;
  box-shadow: none !important;
  padding: 5px 15px;
  height: 47px;
  font-family: $font-regular;
  font-size: 14px;
  color: #544F4F;
}

.custom-modal {
  .modal-dialog {
    //height: calc(100% - 16px);
    //display: flex;
    //justify-content: center;
    //align-items: center;
  }
  &.thank-you-modal {
    .modal-content{
      margin: 0 50px;
    }
    .thank-you-content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 25px 0;
      svg {
        font-size: 58px;
        color: green;
      }
      h1 {
        margin: 25px 0 0 0;
        text-align: center;
        font-size: 22px;
        text-transform: uppercase;
        font-family: $font-semiBold;
        line-height: 25px;
      }
    }
  }
}
.empty-container {
  height: calc(100vh - 423px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.inner-body-with-no-scroll {
  flex: 1;
  overflow-y: auto;
  position: relative;
}
.deal {
  &.variations {
    .variations-container {
      padding: 15px 15px 0;
      .variations-card {
        padding: 20px 10px;
        .list {
          .list-header {
            padding: 0 0 15px;
          }
          .list-item {
            flex-direction: row-reverse;
            .icon {
              margin: 0 0 0 15px;
            }
          }
          .section-slider {
            padding: 0;
            margin: 25px 0 0 0;
            ul{
              &.circle-slider {
                li {
                  width: 82px;
                  min-width: 82px;
                  height: 82px;
                  border-radius: 82px;
                }
              }
            }
          }
        }
      }
      &:last-child {
        padding-bottom: 15px;
      }
    }
  }
}
.common-footer {
  padding: 12px 20px;
  background: white;
  box-shadow: 0 0 5px -2px black;
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: row;
  .counter {
    display: flex;
    align-items: center;
    flex: 1;
    button {
      background: transparent;
      color: #ef2b2d;
      padding: 0 5px;
      outline: none;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
    }
    .counter-value {
      padding: 0 15px;
      font-family: $font-regular;
      font-size: 16px;
    }
  }

}
.loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  z-index: 3;
  .ant-spin {
    span {
      i {
        opacity: 1;
        &:first-child {
          background: #f8c41e;
        }
        &:nth-child(2) {
          background: #dd2528;
        }
        &:nth-child(3) {
          background: #afba4d;
        }
        &:last-child {
          background: #221f1f;
        }
      }
    }
  }
}
.margin-bottom-15 {
  margin-bottom: 15px !important;
}
.m-0 {
  margin: 0 !important;
}
.bold {
  font-family: $font-bold !important;
}


.not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  flex: 1;
  flex-direction: column;
  background-color: white;
  .icon {
   img {
     width: 100px;
     height: 100px;
   }
  }
  p {
    margin: 15px 0;
    font-size: 24px;
    font-family: $font-bold;
    color: $black;
  }
}
.c-btn {
  background: #FCB913 !important;
  color: white !important;
  text-transform: uppercase;
  min-width: 140px;
  border-radius: 5px;
  font-size: 11px;
  font-family: $font-bold;
  padding: 10px 5px;
  border: 1px solid #FCB913 !important;
  outline: none;
  &:disabled {
    opacity: 0.6;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(252, 185, 19, 0.5) !important;
  }
}
.padding-l-r-zero {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.menu {
  display: flex;
  flex-direction: column;
  height: 100%;
  .tab-content {
    flex: 1;
    overflow-y: scroll;
  }
}
.deals-slider {
  .slider-slide {
    min-height: auto !important;
    max-height: 300px !important;
  }
}

.or-separator {
  margin: 25px 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-family: $font-bold;
  font-size: 16px;
}
.price{
  padding-top: 0 !important;
  margin-top: -5px;
}
.closed-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffffe8;
  z-index: 10;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  svg {
    margin-bottom: 25px;
    width: 100px;
    height: 100px;
    fill: red;
  }
  p {
    color: black;
    font-family: $font-bold;
    font-size: 20px;
    margin: 0;
  }
}
.flex-direction-column {
  flex-direction: column;
}
.transparent-loading{
  background: rgba(255, 255, 255, 0.60);
  z-index: 2;
}

.c-btn-big {
  min-width: 200px;
  padding: 12px 5px;
  font-size: 12px;
}
