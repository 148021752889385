.auth {
  padding: 25px 15px;
  display: flex;
  flex-direction: column;
  height: 100%;
  .logo-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 167px;
      height: 89px;
      object-fit: cover;
    }
  }
  .button-container {
    display: flex;
    flex-direction: column;
    button {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.login {
  padding: 25px 15px;
  display: flex;
  flex-direction: column;
  height: 100%;
  .form {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .logo-container {
      margin-bottom: 35px;
      display: flex;
      justify-content: center;
      img {
        width: 167px;
        height: 89px;
        object-fit: cover;
      }
    }
    .link {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 30px;
      a {
        text-decoration: none;
        color: $linkColor;
        font-size: 12px;
        font-family: $font-openSans-semiBold;
      }
    }
    .form-group {
      margin-bottom: 10px;
      position: relative;
      input {
        border-radius: 6px;
        border-color: #E8E8E9 !important;
        box-shadow: none !important;
        padding: 5px 15px 5px 50px;
        height: 47px;
        font-family: $font-regular;
        font-size: 16px;
        color: #544F4F;
        &::placeholder {
          color: #544F4F;
        }
      }
      svg {
        position: absolute;
        top: 11px;
        bottom: 0;
        left: 15px;
        font-size: 24px;
        color: #E8E8E9;
      }
    }
    .text-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 25px 0 15px;
      span {
        color: #2E2460;
        font-family: $font-regular;
        font-size: 18px;
        &:first-child {
          text-transform: uppercase;
        }
        &:last-child{
          font-size: 16px;
        }
      }
    }
    .social-links {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      justify-content: center;
      li {
        margin-right: 12px;
        a {
          width: 42px;
          height: 42px;
          border-radius: 42px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #277BBF;
          svg {
            color: #293680;
            font-size: 18px;
          }
        }
        &:nth-child(2) {
          a {
            background: #82D3F0;
          }
        }
        &:last-child {
          margin-right: 0;
          a {
            background: #FFC90B;
          }
        }
      }
    }
  }
  .bottom-container {
    p {
      margin: 0;
      text-align: center;
      font-family: $font-openSans-light;
      color: #544F4F;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        text-decoration: none;
        text-transform: uppercase;
        font-family: $font-openSans-semiBold;
        color: $linkColor;
        font-size: 15px;
        margin-left: 10px;
      }
    }
  }
}