$primary: #ef2b2d;
$whisper: #ede8e8;
$offWhite: #f8f6f6;
$white: #ffffff;
$black: #000000;
$lightenGray: #E8E8E9;
$darkenGray: #544F4F;
$gray: #6f6f6f;
$green: #6FA442;
$linkColor: #017CA4;

//font families
$font-bold : 'Montserrat-Bold';
$font-black : 'Montserrat-Black';
$font-extraBold : 'Montserrat-ExtraBold';
$font-italic : 'Montserrat-Italic';
$font-light : 'Montserrat-Light';
$font-medium : 'Montserrat-Medium';
$font-regular : 'Montserrat-Regular';
$font-thin : 'Montserrat-Thin';
$font-semiBold : 'Montserrat-SemiBold';
$font-openSans-semiBold : 'OpenSans-SemiBold';
$font-openSans-light : 'OpenSans-Light';
$font-bebasNeue-regular : 'BebasNeue-Regular';
