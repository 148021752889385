.more {
  //height: 100%;
  background: white;
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      a {
        display: flex;
        padding: 15px;
        font-size: 14px;
        font-family: $font-medium;
        color: black;
        transition: 0.3s;
        &:hover{
          background: whitesmoke;
        }
      }
      border-bottom: 1px solid #d4d4d4;
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
.page {
  width: 100%;
  padding: 15px;
  background: white;
  display: flex;
  flex-direction: column;
  flex: 1;
  .page-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    img {
      width: 100%;
      min-width: 200px;
      max-width: 270px;
      object-fit: contain;
    }
  }
  .page-content {



    p {
      font-size: 14px;
      font-family: $font-semiBold;
    }
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        margin-bottom: 15px;
        font-size: 14px;
        font-family: $font-regular;
        ul {
          margin-left: 20px;
          li {
            margin-top: 10px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
.contact-list {
  margin: 20px 15px 0 !important;
  li {
    margin-bottom: 25px !important;
    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      .icon {
        img {
          width: 38px;
          height: 38px;
          object-fit: contain;
        }
      }
      span {
        flex: 1;
        margin-left: 15px;
        font-family: $font-medium;
        color: black;
        font-size: 16px;
        word-break: break-all;
        line-height: 18px;
      }
    }
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}

.locations {
  .location {
    .location-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 20px 0;
      img {
        width: 15px;
        height: 25px;
        margin-bottom: 10px;
      }
      h1 {
        margin: 0;
        font-size: 24px;
        font-family: $font-bold;
        text-transform: capitalize;
        color: black;
      }
    }
    .location-container {
      ul {
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        li {
          margin: 0 0 15px;
          border-bottom: 1px solid #fec50a;
          width: 100%;
          padding: 0 0 15px 0;
          .address-header {
            margin-bottom: 10px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            h1 {
              margin: 0;
              font-size: 14px;
              font-family: $font-semiBold;
              color: black;
            }
            span {
              font-size: 14px;
              font-family: $font-regular;
              color: black;
            }
            &:before {
              content: '';
              width: 12px;
              height: 12px;
              border-radius: 12px;
              display: inline-block;
              background: black;
              margin-right: 10px;
              margin-left: 3px;
            }
          }
          .address-timing {
            margin-bottom: 10px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            img {
              width: 16px;
              height: 16px;
              margin-right: 10px;
            }
            p {
              margin: 0;
              font-size: 14px;
              color: black;
              font-family: $font-regular;
              b {
                font-family: $font-medium;
              }
            }
          }
          .address-address {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            img {
              width: 16px;
              height: 24px;
              margin-right: 10px;
            }
            p {
              margin: 0;
              font-size: 14px;
              color: black;
              font-family: $font-regular;
              b {
                font-family: $font-medium;
              }
            }
          }
        }
      }
    }
    &:first-child {
      .location-header {
        margin-top: 0;
      }
    }
  }
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.65);
  background-color: rgba(255, 197, 12, 0.2);
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: #ef2b2d;
}
.form {
  .ant-form-item {
    flex-direction: column;
    margin-bottom: 15px;
    .ant-form-item-label {
      text-align: left;
      label {
        font-family: $font-regular;
        font-size: 14px !important;
        margin-bottom: 0 !important;
      }
    }
    .ant-form-item-control-input-content{
      .ant-input {
        font-size: 14px;
        font-family: $font-regular;
        min-height: 38px;
        &:hover{
          border-color: #ffc50b;
        }
        &:focus {
          border-color: #ffc50b;
          border-right-width: 1px !important;
          outline: 0;
          box-shadow: 0 0 0 2px rgba(255, 197, 12, 0.2);
        }
      }
      .ant-input-focused {
        border-color: #ffc50b;
        border-right-width: 1px !important;
        outline: 0;
        box-shadow: 0 0 0 2px rgba(255, 197, 12, 0.2);
      }
      .ant-select {
        .ant-select-selector {
          min-height: 38px;
          &:hover{
            border-color: #ffc50b;
          }
          &:focus {
            border-color: #ffc50b;
            border-right-width: 1px !important;
            outline: 0;
            box-shadow: 0 0 0 2px rgba(255, 197, 12, 0.2);
          }
        }
      }
      .ant-select-focused.ant-select-multiple .ant-select-selector {
        border-color: #ffc50b;
        border-right-width: 1px !important;
        outline: 0;
        box-shadow: 0 0 0 2px rgba(255, 197, 12, 0.2);
      }
      .ant-select-multiple {
        .ant-select-selection-item {
          font-family: $font-regular;
        }
      }
    }
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    padding: 0;
    line-height: 36px;
  }
  .button-container {
    margin-top: 15px;
    display: flex;
    justify-content: center;
  }
}
.ant-input-number{
  width: 100%;
  .ant-input-number-input-wrap {
    input {
      font-size: 14px;
      font-family: $font-regular;
      min-height: 38px;

    }
  }
  &:hover{
    border-color: #ffc50b;
  }
  &:focus {
    border-color: #ffc50b;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(255, 197, 12, 0.2);
  }
  &.ant-input-focused {
    border-color: #ffc50b;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(255, 197, 12, 0.2);
  }
}
