.your-order {
  height: 100%;
  .your-order-inner-container {
    padding: 15px;
    .page-title {
      margin: 15px 0;
      font-size: 18px;
      text-align: center;
      font-family: $font-medium;
      text-transform: uppercase;
    }
    .border-bottom-white{
      border-bottom-color: #fff !important;
    }
    .table-container {
      position: relative;
      .close-button {
        padding: 0;
        background: transparent;
        border: none;
        font-size: 22px;
        line-height: 20px;
        outline: none;
      }
      table {
        background: white;
        th, td{
          border-color: black;
          padding: 10px;
        }
        thead {
          background: #eee;
          th {
            border-bottom-width: 1px;
            font-size: 12px;
            font-family: $font-regular;
          }
        }
        tbody{
          tr {
            td {
              font-size: 12px;
              color: black;
              font-family: $font-regular;
            }
          }
          .item {
            td {
              font-size: 12px;
              color: black;
              font-family: $font-regular;
            }
          }
          .footer-item {
            td {
              font-size: 12px;
              color: black;
              font-family: $font-bold;
            }
          }
        }
      }
    }
    .info {
      font-family: $font-medium;
      font-size: 14px;
    }
    .promo-section {
      margin: 25px 0 40px;
      display: flex;
      flex-direction: column;
      width: 100%;
      .promo-section-title {
        color: black;
        font-family: $font-medium;
        margin-bottom: 5px;
        font-size: 14px !important;
      }
      .promo-section-form {
        display: flex;
        flex-direction: row;
        .ant-form-item {
          margin: 0;
          input {
            font-size: 14px;
            font-family: "Montserrat-Regular";
            min-height: 39px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            box-shadow: none !important;
          }
          .c-btn {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            box-shadow: none !important;
          }
          &:first-child {
            flex: 1;
          }
        }
      }
      label {

      }
      .input-group {
        .input-group-append {
          margin-left: 0;
        }
        button {
          padding: 5px 30px;
          font-family: $font-semiBold;
          font-size: 14px;
          box-shadow: none;
          outline: none;
        }
        input {
          outline: none;
          border: none;
          border-radius: 6px 0 0 6px;
          border-color: #E8E8E9 !important;
          box-shadow: none !important;
          padding: 5px 15px;
          height: 47px;
          font-family: $font-regular;
          font-size: 14px;
          color: #544F4F;
        }
      }

    }


    .form-section {
      margin: 15px 0 25px;
      display: flex;
      flex-direction: column;
      .form-group {
        .form-label {
          font-family: $font-medium;
          font-size: 12px;
        }
        textarea{
          resize: none;
        }
        select {
          outline: none;
          border: none;
          border-radius: 6px;
          border-color: #E8E8E9 !important;
          box-shadow: none !important;
          padding: 5px 15px;
          height: 47px;
          font-family: $font-regular;
          font-size: 14px;
          color: #544F4F;
        }
        &.payment-methods-group {
          .payment-methods {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            padding: 10px 0 0;
            .payment-method {
              display: flex;
              flex-direction: row;
              margin: 0 10px 15px;
              padding: 0;
              align-items: center;
              cursor: pointer;
              span {
                font-size: 12px;
                font-family: $font-regular;
                color: #000000;
                line-height: 12px;
                &.active {
                  border-color: #E21D30 !important;
                  &:before {
                    background: #E21D30 !important;
                  }
                }
                &:first-child {
                  width: 16px;
                  height: 16px;
                  border-radius: 16px;
                  border: 1px solid #9B9A9A;
                  margin-right: 10px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  &:before {
                    content: '';
                    width: 8px;
                    height: 8px;
                    border-radius: 8px;
                    display: flex;
                    background: transparent;
                  }
                }
                &:nth-child(2) {
                  flex: 1;
                }
                &:last-child {}
              }
              &:first-child {
                margin-left: 0;
              }
              &:last-child {
                margin-right: 0;
              }
            }
          }
          .invalid-feedback {
            display: block;
          }
        }
      }
      .btn {
        margin: 35px auto 0;
        padding: 9px 45px;
        background: #ef2b2d;
        border-color: #ef2b2d;
        outline-color: #ef2b2d;
        color: $white;
        display: flex;
        justify-content: center;
        outline: none !important;
        box-shadow: none !important;
        font-size: 14px;
        font-family: $font-regular;
      }
    }
  }
}
