.main-slider {

  .slider-slide {
    height: auto;
    display: flex !important;
    min-height: 250px;
    max-height: 400px;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
  .slick-dots {
    bottom: 15px;
    li {
      width: 39px;
      height: 10px;
      &.slick-active {
        button {
          background: transparent;
          border-color: #C5C3C1;
        }
      }
      button {
        width: 39px;
        padding: 0;
        height: 10px;
        background: white;
        transition: 0.3s;
        border-radius: 100px;
        border: 2px solid white;

        &:before {
          display: none;
        }
      }
    }
  }
}
